













































import { Component, Vue } from 'vue-property-decorator';
import EntriesCard from '@/components/cards/EntriesCard.vue';
import VButton from '@/components/VButton.vue';
import { Action } from 'vuex-class';
import * as types from '@/store/main/types';

@Component({
  components: { EntriesCard, VButton },
})
export default class KennisBankCaos extends Vue {

  @Action('getKennisBankCaos', { namespace: 'main' }) private getKennisBankCaos!: types.GetKennisbankCaosAction;

  private entries: Array<{ header: string; text: string; category: string }> = [];
  private loadingCaoItems = false;
  private searchText = '';

  private get trimmedSearchText() {
    return this.searchText.trim().toLowerCase();
  }

  private get searchedEntries() {
    return this.entries.filter((entry) => {
      return (entry.text.toLowerCase().includes(this.trimmedSearchText)) ||
      (entry.header.toLowerCase().includes(this.trimmedSearchText)) ||
      (entry.category.toLowerCase().includes(this.trimmedSearchText));
    });
  }

  private created() {
    this.loadingCaoItems = true;
    this.getKennisBankCaos().then((response) => {
      this.entries = response.map((caoItem) => {
        const result = { header: caoItem.question, text: caoItem.answer, category: caoItem.cao_type };
        if (caoItem.source !== '') {
          (result as any).source = caoItem.source;
        }
        return result;
      }).sort((a, b) => a.category > b.category ? 1 : -1);
    }).finally(() => {
      this.loadingCaoItems = false;
    });
  }
}
