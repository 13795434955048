






































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class EntriesCard extends Vue {

  @Prop({ default: '' }) private header!: string;
  @Prop({ default: '' }) private foundName!: string;
  @Prop({ default: true }) private search!: boolean;
  @Prop({ default: false }) private loading!: boolean;
  @Prop({ default: () => [] }) private entries!: Array<{ header: string, text: string, category: string }>;
  @Prop({
    default: () => (entry: any, search: string) => {
      return (entry.text.toLowerCase().includes(search)) ||
      (entry.header.toLowerCase().includes(search)) ||
      (entry.category.toLowerCase().includes(search));
    },
  }) private searchFunction!: (entry: any, search: string) => boolean;

  private searchText = '';
  private currentPage = 1;
  private pageLength = 20;

  get trimmedSearchText() {
    return this.searchText.trim().toLowerCase();
  }

  /**
   * Filter entries to only return those who contain the search string
   */
  get filteredEntries() {
    return this.entries.filter((entry) => {
      return this.searchFunction(entry, this.trimmedSearchText);
    });
  }

  /**
   * Paginate the filtered entries to get a single page's length
   */
  get paginatedEntries() {
    return this.filteredEntries.slice((this.currentPage - 1) * this.pageLength, this.currentPage * this.pageLength);
  }
}
